import React from 'react';
import MaxWidth from '../components/MaxWidth/MaxWidth';
import Meta from '../components/Meta/Meta';

export default function About() {
  return (
    <MaxWidth>
      <Meta title="Contact | Ottone Photography" />
      <p>For print purchase or commission enquiries, please email:</p>
      <p>michaela.ottone@gmail.com</p>
      <br />
      {/* <a style={{ color: 'white' }} href="https://www.instagram.com/ottonephotography/">
        Instagram
      </a> */}
    </MaxWidth>
  );
}
